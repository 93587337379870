import React, { useEffect, useState } from 'react';
import { Container, Paper, TextField } from '@material-ui/core';
import { useAuth } from '@context/auth';
import { Button } from '@components/Button';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import {
  Card,
  CarouselStyled,
  ContainerButtons,
  ContainerSignIn,
  Form,
  TitleSignIn
} from './styles';
import backgroundImage from '../../assets/prancheta2.png';
const signInForm = z.object({
  email: z.string().email({ message: 'E-mail invávido ou não cadastrado.' }),
  password: z
    .string()
    .min(4, { message: 'A senha deve conter mais de 4 digitos.' })
});

type SignForm = z.infer<typeof signInForm>;

export default function SignIn() {
  const [count, setCount] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<SignForm>();

  const {
    signIn,
    validateEmailUser,
    validateEmail,
    handlePrevState,
    messageError
  } = useAuth();

  function handleLogin(data: SignForm) {
    const { email, password } = data;

    if (count === 0) {
      validateEmailUser(email); return;
    }

    signIn(password);
  }

  function handleNextField() {
    if (count < 1) {
      setCount(count + 1);
    }
  }

  function handlePrevField() {
    if (count >= 1) {
      setCount(count - 1);
      handlePrevState();
    }
  }

  useEffect(() => {
    if (validateEmail) {
      handleNextField();
    } else if (!validateEmail) {
      setCount(0);
    }
  }, [validateEmail]);

  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <ContainerSignIn>
      
        <Card>
          <Paper style={{ width: 400, height: count >= 1 ? 300 : 240 }}>
            <TitleSignIn>    <img src={backgroundImage} height={50} width={100}/>           </TitleSignIn>
            <Form onSubmit={handleSubmit(handleLogin)}>
              <CarouselStyled index={count}>
                <TextField
                  type="email"
                  id="email"
                  fullWidth
                  label="E-mail"
                  variant="outlined"
                  helperText={messageError}
                  error={!!messageError}
                  {...register('email')}
                />

                <TextField
                  type="password"
                  id="password"
                  fullWidth
                  label="Senha"
                  variant="outlined"
                  helperText={messageError}
                  error={!!messageError}
                  {...register('password')}
                />
              </CarouselStyled>

              <ContainerButtons>
                {count === 1 && (
                  <Button
                    title="Entrar"
                    variantstyle={isSubmitting ? 'DEFAULT' : 'PURPLE'}
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  />
                )}
                {count !== 1 && (
                  <Button
                    title="Próximo"
                    type="submit"
                    isLoading={isSubmitting}
                    variantstyle={isSubmitting ? 'DEFAULT' : 'PURPLE'}
                  />
                )}
                {count === 1 && (
                  <Button
                    title="Voltar"
                    variantstyle="DEFAULT"
                    onClick={handlePrevField}
                  />
                )}
              </ContainerButtons>
            </Form>
          </Paper>
        </Card>
      </ContainerSignIn>
    </Container>
  );
}
