import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { maskCEP, maskCNPJ, maskCPF, maskDate } from '@utils/masks';

interface IClinica {
  id_clinicas_credenciadas: number;
  contrato: string;
  nome_comercial: string;
  razao_social: string;
  cpf_cnpj: string;
  dias_funcionamento: string;
  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  nome_med: string;
  crm: string;
  crm_estado: string;
  trabalho_agendamento: string;
  pagamento_adiantado: string;
  telemedicina: string;
  observacao: string;
}

interface IAgendamento {
  cidade: string;
  cpf_cnpj_comer: string;
  cpf_colaborador: string;
  data_agendamento: string;
  estado: string;
  horario: string;
  id_agendamento: number;
  nome: string;
  nome_comercial: string;
  num_agenda: string;
  observacao: string;
  ordem_chegada: string;
  status: string;
  telemedicina: string;
  tipo_aso: string;
  usuario: string;
}

interface IColaborador {
  id_colaboradores: number;
  matricula_empresa: string;
  matricula_esocial: string;
  nome_completo: string;
  cpf_colaborador: string;
  rg: string;
  data_nascimento: string;
  genero: string;
  cnh: string;
  email: string;
  telefone: string;
  situacao: string;
  nis: string;
  funcao: string;
  setor: string;
  turno: string;
  cnpj: string;
  razao_social: string;
  data_admissao: string;
  horario_trab_entrada: string;
  horario_trab_saida: string;
  local_obra: string;
  cod_ghe: string;
  ghe: string;
}

interface IEmpresaColab {
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  estado: string;
  numero: string;
  rua: string;
}

interface ITelefoneEmpresa {
  cnpj: string;
  id_empresa_telefone: number;
  telefone: string;
}

interface IExameGHE {
  id_exame_ghe: number;
  cod_ghe: string;
  exame_empresa: string;
  dias: string;
  data: [
    {
      data_agendamento: string;
    }
  ];
}

interface IGhe {
  cod_ghe: string;
  cod_obra: string;
  crm: string;
  crm_estado: string;
  exibir: string;
  id_cad_ghe: number;
  nome_ghe: string;
  nome_med: string;
  obs_aso: string;
  tipo_plano: string;
  valor_plano: string;
}

interface IRisco_Ghe {
  id_risco_ghe: number;
  cod_ghe: string;
  nome: string;
}

export interface Credenciado {
  cpf_cnpj_comer: string;
  data_agendamento: string;
  exames: ExameCredenciado[];
  horario: string;
  id_exame_hibrido: number;
  linkDoatendimento: string;
  nome_comercial: string;
  num_agenda: string;
  ordem_chegada: string;
  tipo: string;
  tipo_agendamento: string;
}

export interface ExameCredenciado {
  exame_credenciado: string;
  valor_credenciado: string;
}

interface IPdf {
  clinica: IClinica[];
  agendamento: IAgendamento[];
  colaborador: IColaborador[];
  exames_ghe: IExameGHE[];
  ghe: IGhe[];
  risco_ghe: IRisco_Ghe[];
  empresa_colab: IEmpresaColab[];
  telefone_empresa: ITelefoneEmpresa[];
  exames_hibrido: Credenciado[];
}

export const PDF = (data: IPdf[], page: string) => {
  function selectCpfOrCnpj(cpfOrCnpj: string) {
    if (cpfOrCnpj.length <= 11) {
      return maskCPF(cpfOrCnpj);
    } else {
      return maskCNPJ(cpfOrCnpj);
    }
  }

  // Logica de Riscos
  const numberOfColumnsRiscos = 2;
  const numberOfRowsRiscos = Math.ceil(
    data[0].risco_ghe.length / numberOfColumnsRiscos
  );

  const leftColumnRiscos = [];
  const rightColumnRiscos = [];
  const leftLengthRiscos = [];

  for (let i = 0; i < numberOfRowsRiscos; i++) {
    leftColumnRiscos[i] = data[0].risco_ghe[i * numberOfColumnsRiscos];
    rightColumnRiscos[i] = data[0].risco_ghe[i * numberOfColumnsRiscos + 1];
    leftLengthRiscos[i] = i;
  }

  const rightRiscos = rightColumnRiscos.map((right) => {
    if (right !== undefined) {
      return [{ text: right?.nome ? right?.nome : '', colSpan: 3 }, {}, {}];
    } else {
      return [{ text: '', colSpan: 3 }, {}, {}];
    }
  });
  const leftRiscos = leftColumnRiscos.map((left) => {
    if (left !== undefined) {
      return [{ text: left?.nome ? left?.nome : '', colSpan: 3 }, {}, {}];
    } else {
      return [{ text: '', colSpan: 3 }, {}, {}];
    }
  });

  const countRiscos = leftLengthRiscos.map((a, i) => [
    ...leftRiscos[i],
    ...rightRiscos[i]
  ]); // organiza por index as linhas da coluna de riscos
  //= ====================================Fim da logica de riscos =============================================

  // Aqui divide em duas colunas os exames
  const numberOfColumns = 2;
  const numberOfRows = Math.ceil(data[0].exames_ghe.length / numberOfColumns);

  const leftColumn = [];
  const rightColumn = [];
  const leftLength = [];

  for (let i = 0; i < numberOfRows; i++) {
    leftColumn[i] = data[0].exames_ghe[i * numberOfColumns];
    rightColumn[i] = data[0].exames_ghe[i * numberOfColumns + 1];
    leftLength[i] = i;
  }

  const right = rightColumn.map((right, i) => {
    if (right !== undefined) {
      return [
        {
          text: right?.exame_empresa ? right?.exame_empresa : '',
          colSpan: 2,
          border: [true, true, false, true]
        },
        {},
        {
          text: `${
            right.data[0]?.data_agendamento
              ? maskDate(right.data[0]?.data_agendamento)
              : ''
          } `,
          border: [false, true, true, true],
          bold: true
        }
      ];
    } else {
      return [
        { text: '', colSpan: 2, border: [true, true, false, true] },
        {},
        { text: `${''}`, border: [false, true, true, true], bold: true }
      ];
    }
  });

  const left = leftColumn.map((left) => {
    if (left !== undefined) {
      return [
        {
          text: left?.exame_empresa ? left?.exame_empresa : '',
          colSpan: 2,
          border: [true, true, false, true]
        },
        {},
        {
          text: `${
            left.data[0]?.data_agendamento
              ? maskDate(left.data[0]?.data_agendamento)
              : ''
          } `,
          border: [false, true, true, true],
          bold: true
        }
      ];
    } else {
      return [
        { text: '', colSpan: 2, border: [true, true, false, true] },
        {},
        { text: `${''}`, border: [false, true, true, true], bold: true }
      ];
    }
  });

  const countExames = leftLength.map((a, i) => [...left[i], ...right[i]]); // organiza por index as linhas da coluna de exames

  //= ===============================Fim da logica de exames ======================================================

  // Aqui divide em duas colunas os exames

  const dataArrayCredenciado = data[0].exames_hibrido?.flatMap(
    (e, i) => e.exames
  )
    ? data[0].exames_hibrido?.flatMap((e, i) => e.exames)
    : [];
  // Aqui divide em duas colunas os exames-hibrido
  const numberOfColumnsExamesHibrido = 2;
  const numberOfRowsExamesHibrido = Math.ceil(
    dataArrayCredenciado.length / numberOfColumnsExamesHibrido
  );

  const leftColumnExamesHibrido = [];
  const rightColumnExamesHibrido = [];
  const leftLengthExamesHibrido = [];

  for (let i = 0; i < numberOfRowsExamesHibrido; i++) {
    leftColumnExamesHibrido[i] =
      dataArrayCredenciado[i * numberOfColumnsExamesHibrido];
    rightColumnExamesHibrido[i] =
      dataArrayCredenciado[i * numberOfColumnsExamesHibrido + 1];
    leftLengthExamesHibrido[i] = i;
  }

  const rightExamesHibrido = rightColumnExamesHibrido.map((right, i) => {
    if (right !== undefined) {
      return [
        {
          text: right?.exame_credenciado ? right?.exame_credenciado : '',
          colSpan: 2,
          border: [true, true, false, true]
        },
        {},
        {
          text: data[0].exames_hibrido[i]?.data_agendamento
            ? data[0].exames_hibrido[i]?.data_agendamento
            : '',
          border: [false, true, true, true],
          bold: true
        }
      ];
    } else {
      return [
        { text: '', colSpan: 2, border: [true, true, false, true] },
        {},
        { text: `${''}`, border: [false, true, true, true], bold: true }
      ];
    }
  });

  const leftExamesHibrido = leftColumnExamesHibrido.map((left, i) => {
    if (left !== undefined) {
      return [
        {
          text: left?.exame_credenciado ? left?.exame_credenciado : '',
          colSpan: 2,
          border: [true, true, false, true]
        },
        {},
        {
          text: data[0].exames_hibrido[i]?.data_agendamento
            ? data[0].exames_hibrido[i]?.data_agendamento
            : '',
          border: [false, true, true, true],
          bold: true
        }
      ];
    } else {
      return [
        { text: '', colSpan: 2, border: [true, true, false, true] },
        {},
        { text: `${''}`, border: [false, true, true, true], bold: true }
      ];
    }
  });

  const countExamesExamesHibrido = leftLengthExamesHibrido.map((a, i) => [
    ...leftExamesHibrido[i],
    ...rightExamesHibrido[i]
  ]); // organiza por index as linhas da coluna de exames

  //= ===============================Fim da logica de exames hibrido ======================================================

  const selectTypeExames =
    page === 'HIBRIDO' ? countExamesExamesHibrido : countExames;
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      text: '',
      fontSize: 15,
      bold: true,
      margin: [15, 30, 0, 45] // left, top, right, bottom
    }
  ];
  const details = [
    {
      style: { fontSize: 10 },
      color: '#444',
      table: {
        widths: [80, 80, 60, 100, '*', 60],
        // widths: ['*', '*', '*', '*', '*', '*'],
        headerRows: 2,
        body: [
          [
            {
              text: [
                {
                  text: String(
                    'ATESTADO DE SAÚDE OCUPACIONAL'
                  ).toLocaleUpperCase(),
                  style: 'tableHeader',
                  fontSize: 18,
                  alignment: 'center'
                },
                {
                  text: '\n',
                  alignment: 'center'
                },
                {
                  text: '\n(11) 96621 8866 - www.aryz.com.br - agendamento@aryz.com.br ',
                  alignment: 'center'
                }
              ],
              colSpan: 6,
              color: '#6D539E'
            },
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Dados da Empresa',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            },
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Empresa',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0].colaborador[0]?.razao_social, colSpan: 2 },
            {},
            {
              text: 'CNPJ',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].colaborador[0]?.cnpj
                ? selectCpfOrCnpj(data[0]?.colaborador[0]?.cnpj)
                : '',
              colSpan: 2
            },
            {}
          ],
          [
            {
              text: 'Endereço',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: `${data[0]?.empresa_colab[0]?.rua}, ${data[0]
                ?.empresa_colab[0]?.numero}, ${data[0]?.empresa_colab[0]
                ?.complemento}, ${data[0]?.empresa_colab[0]?.bairro} - ${data[0]
                ?.empresa_colab[0]?.estado}, ${data[0]?.empresa_colab[0]
                ?.cidade}, ${
                data[0].empresa_colab[0]?.cep
                  ? maskCEP(data[0].empresa_colab[0]?.cep)
                  : ''
              }`,
              colSpan: 2
            },
            {},
            {
              text: 'Tel',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0].telefone_empresa[0]?.telefone, colSpan: 2 },
            {}
          ],

          [
            {
              text: 'Dados do Colaborador',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: 'Colaborador',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].colaborador[0]?.nome_completo
                ? data[0].colaborador[0]?.nome_completo
                : '',
              colSpan: 5
            }
          ],
          [
            {
              text: 'Setor',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].colaborador[0]?.setor
                ? data[0].colaborador[0]?.setor
                : ''
            },
            {
              text: 'Função',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].colaborador[0]?.funcao
                ? data[0].colaborador[0]?.funcao
                : '',
              colSpan: 3
            }
          ],
          [
            {
              text: 'CPF',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].colaborador[0]?.cpf_colaborador
                ? data[0].colaborador[0]?.cpf_colaborador
                : ''
            },
            {
              text: 'RG ou CTPS',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].colaborador[0]?.rg
                ? data[0].colaborador[0]?.rg
                : '',
              colSpan: 3
            }
          ],
          [
            {
              text: 'Nascimento',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].colaborador[0]?.data_nascimento
                ? maskDate(data[0].colaborador[0]?.data_nascimento)
                : ''
            },
            {
              text: 'GHE',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.ghe[0]?.nome_ghe },
            {
              text: 'Sexo',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0]?.genero }
          ],

          [
            {
              text: 'Dados do Agendamento',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: 'Resp. do PCMSO',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center',
              fontSize: 9
            },
            { text: data[0]?.ghe[0]?.nome_med, colSpan: 3 },
            {},
            {},
            {
              text: 'CRM',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: `${data[0]?.ghe[0]?.crm}/${data[0]?.ghe[0]?.crm_estado}` }
          ],
          [
            {
              text: 'Tipo de ASO',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.agendamento[0]?.tipo_aso, colSpan: 5 }
          ],

          [
            {
              text: 'Riscos Ocupacionais',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],

          ...countRiscos,

          [
            {
              text: 'Exames Complementares Realizados',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],

          ...selectTypeExames,

          [
            {
              text: 'Observações do ASO',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          // Observaçõe aso
          [
            {
              text: data[0].ghe[0]?.obs_aso ? data[0].ghe[0]?.obs_aso : '',
              colSpan: 6,
              rowSpan: 3
            }
          ],
          [{}, {}, {}, {}, {}, {}],
          [{}, {}, {}, {}, {}, {}],
          // Colunas de assinaturas
          [
            {
              text: 'Aprovação e Assinatura',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              rowSpan: 3,
              text: 'Data do ASO',
              alignment: 'center',
              margin: [5, 12, 10, 0],
              fillColor: '#88CBC5',
              color: '#000',
              bold: true
            },
            {
              rowSpan: 3,
              text: '___/___/___',
              alignment: 'center',
              width: 10,
              margin: [5, 12, 10, 0]
            },
            {
              rowSpan: 3,
              colSpan: 2,
              text: [{ text: 'Resultado' }, { text: '\n(indicar com um "X")' }],
              margin: [5, 5, 10, 0],
              alignment: 'center',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true
            },
            {},
            { text: 'APTO', alignment: 'center', fontSize: 8 },
            {}
          ],
          [
            {},
            {},
            {},
            {},
            { text: 'APTO C/ RESTRIÇÕES', alignment: 'center', fontSize: 8 },
            {}
          ],
          [
            {},
            {},
            {},
            {},
            { text: 'INAPTO', alignment: 'center', fontSize: 8 },
            {}
          ],
          // medico
          [
            { text: '\n\n\n', colSpan: 3 },
            {},
            {},
            { text: '\n\n\n', colSpan: 3 },
            {},
            {}
          ],
          // label
          [
            {
              text: 'Médico Examinador',
              colSpan: 3,
              alignment: 'center',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true
            },
            {},
            {},
            {
              text: [
                { text: 'Colaborador' },
                { text: '\nDECLARO TER RECEBIDO UMA VIA DESTE ATESTADO' }
              ],
              colSpan: 3,
              alignment: 'center',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true
            },
            {},
            {}
          ]
        ]
      }
    }
  ];

  /* const fichaClinica = [
    {
      style: { fontSize: 9 },

      color: '#444',
      table: {
        widths: [100, '*', 25, 25, '*', 50, 25, 25],
        headerRows: 2,
        body: [
          [
            {
              text: [
                {
                  text: String('FICHA CLÍNICA OCUPACIONAL').toUpperCase(),
                  style: 'tableHeader',
                  fontSize: 18,
                  alignment: 'center'
                },
                {
                  text: '\n(11) 96621 8866 - www.ravim.health - agendamento@ravim.health',
                  alignment: 'center'
                }
              ],
              colSpan: 8,
              color: '#6D539E'
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Dados da Empresa',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Empresa',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].colaborador[0]?.razao_social
                ? data[0].colaborador[0]?.razao_social
                : '',
              colSpan: 3
            },
            {},
            {},
            {
              text: 'CNPJ',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].colaborador[0]?.cnpj
                ? data[0].colaborador[0]?.cnpj
                : '',
              colSpan: 3
            },
            {},
            {}
          ],


          [
            {
              text: 'Dados do Colaborador',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: 'Colaborador',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].colaborador[0]?.nome_completo
                ? data[0].colaborador[0]?.nome_completo
                : '',
              colSpan: 7
            },
            {},
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Setor',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].colaborador[0]?.setor
                ? data[0].colaborador[0]?.setor
                : ''
            },
            {
              text: 'Função',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center',
              colSpan: 2
            },
            {},
            {
              text: data[0].colaborador[0]?.funcao
                ? data[0].colaborador[0]?.funcao
                : '',
              colSpan: 4
            },
            {},
            {},
            {}
          ],
          [
            {
              text: 'CPF',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].colaborador[0]?.cpf_colaborador
                ? data[0].colaborador[0]?.cpf_colaborador
                : ''
            },
            {
              text: 'RG ou CTPS',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center',
              colSpan: 2
            },
            {},
            {
              text: data[0].colaborador[0]?.rg
                ? data[0].colaborador[0]?.rg
                : '',
              colSpan: 4
            },
            {},
            {},
            {}
          ],
          [
            {
              text: 'Nascimento',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].colaborador[0]?.data_nascimento
                ? maskDate(data[0].colaborador[0]?.data_nascimento)
                : ''
            },
            {
              text: 'GHE',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center',
              colSpan: 2
            },
            {},
            {
              text: data[0].colaborador[0]?.setor
                ? data[0].colaborador[0]?.setor
                : ''
            },
            {
              text: 'Sexo',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0].colaborador[0]?.genero, colSpan: 2 },
            {}
          ],

          [
            {
              text: 'Dados do Agendamento',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: 'Resp. do PCMSO',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center',
              fontSize: 9
            },
            {
              text: data[0].ghe[0]?.nome_med ? data[0].ghe[0]?.nome_med : '',
              colSpan: 3
            },
            {},
            {},
            {
              text: 'CRM',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: `${data[0].ghe[0]?.crm ? data[0].ghe[0]?.crm : ''}/${
                data[0].ghe[0]?.crm_estado ? data[0].ghe[0]?.crm_estado : ''
              }`,
              colSpan: 3
            },
            {},
            {}
          ],

          [
            {
              text: 'Levantamento de Dados e Antecendentes Familiares',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: 'Peso (kg)',
              fillColor: '#88CBC5',
              aligment: 'center',
              margin: [0, 5, 10, 0],
              fontSize: 12,
              bold: true
            },
            { text: dataKit.kit ? dataKit.kit[0]?.peso : '', colSpan: 2 },
            {},
            {
              text: 'Altura (m)',
              fillColor: '#88CBC5',
              aligment: 'center',
              margin: [0, 5, 10, 0],
              fontSize: 12,
              bold: true,
              colSpan: 2
            },
            {},
            { text: dataKit.kit ? dataKit.kit[0]?.altura : '', colSpan: 3 },
            {},
            {}
          ],
          [
            {
              text: 'Sua esposa ou qualquer um de seus familiares (pai, mãe, irmãos, mesmos que falecidos) tem ou tiveram algumas das doenças abaixo?',
              colSpan: 8,
              fillColor: '#88CBC5',
              fontSize: 9,
              alignment: 'center'
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {}
          ],

          [
            {
              text: 'Doença',
              style: 'tableHeader',
              fillColor: '#818181',
              color: '#FFF'
            },
            { text: 'Parentesco', fillColor: '#818181', color: '#FFF' },
            {
              text: 'Sim',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Não',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            { text: 'Doença', fillColor: '#818181', color: '#FFF' },
            { text: 'Parentesco', fillColor: '#818181', color: '#FFF' },
            {
              text: 'Sim',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Não',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            }
          ],
          [
            { text: 'Diabetes', width: 1000 },
            { text: dataKit.kit[0]?.Parentesco_1 },
            {
              text: dataKit.kit[0]?.Diabetes_1 === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Diabetes_1 === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Cancêr' },
            { text: dataKit.kit[0]?.Parentesco_5 },
            {
              text: dataKit.kit[0]?.Cancer === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Cancer === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Doença do Coração' },
            { text: dataKit.kit[0]?.Parentesco_2 },
            {
              text: dataKit.kit[0]?.Doenca_do_Coracao === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Doenca_do_Coracao === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Pressão Alta' },
            { text: dataKit.kit[0]?.Parentesco_6 },
            {
              text: dataKit.kit[0]?.Pressao_Alta === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Pressao_Alta === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Doença Psiquiátricas' },
            { text: dataKit.kit[0]?.Parentesco_3 },
            {
              text: dataKit.kit[0]?.Doenca_Psiquiatricas === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Doenca_Psiquiatricas === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Alergias' },
            { text: dataKit.kit[0]?.Parentesco_7 },
            {
              text: dataKit.kit[0]?.Alergias === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Alergias === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Tuberculose' },
            { text: dataKit.kit[0]?.Parentesco_4 },
            {
              text: dataKit.kit[0]?.Tuberculose === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Tuberculose === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: `Outras: ${dataKit.kit[0]?.Nome_doenca}` },
            { text: dataKit.kit[0]?.Parentesco_8 },
            {
              text: dataKit.kit[0]?.Outras === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Outras === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],

          [
            {
              text: 'Antecendentes Pessoais',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: 'Sofreu ou sofre alguma doença ou situação abaixo?Sua esposa ou qualquer um de seus familiares (pai, mãe, irmãos, mesmos que falecidos) tem ou tiveram algumas das doenças abaixo?',
              colSpan: 8,
              fillColor: '#88CBC5',
              fontSize: 9,
              alignment: 'center'
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Doença ou Situação',
              colSpan: 2,
              fillColor: '#818181',
              color: '#FFF'
            },
            {},
            {
              text: 'Sim',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Não',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Doença ou Situação',
              colSpan: 2,
              fillColor: '#818181',
              color: '#FFF'
            },
            {},
            {
              text: 'Sim',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Não',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            }
          ],
          [
            { text: 'Doenças do coração-Pressão Alta', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Doencas_do_coracao_Pressoao_Alta === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Doencas_do_coracao_Pressoao_Alta === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            { text: 'Varizes', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Varizes === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Varizes === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Bronquite, Asma, Rinite', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Bronquite_Asma_Rinite === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Bronquite_Asma_Rinite === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Hemorroidas', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Hemorroidas === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Hemorroidas === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Doenças renais', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Doencas_renais === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Doencas_renais === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Hérnias', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Hernias === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Hernias === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Doenças do fígado', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Doencas_do_figado === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Doencas_do_figado === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Alguma deficiência física', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Alguma_deficiencia_fisica === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Alguma_deficiencia_fisica === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Diabetes', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Diabetes_2 === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Diabetes_2 === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Já foi submetido a cirurgia?', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Ja_foi_submetido_a_cirurgia === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Ja_foi_submetido_a_cirurgia === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Doenças do estômago', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Doencas_do_estomago === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Doencas_do_estomago === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Já esteve internado?', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Ja_esteve_internado === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Ja_esteve_internado === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Resfriados frequentes, Sinusite', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Resfriados_frequentes_Sinusite === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Resfriados_frequentes_Sinusite === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            { text: 'Dificuldade em tarefa pesada?', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Dificuldade_em_tarefa_pesada === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Dificuldade_em_tarefa_pesada === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Problemas de visão', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Problemas_de_visao === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Problemas_de_visao === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Tabagismo?', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Tabagismo === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Tabagismo === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Problemas de audição', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Problemas_de_audicao === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Problemas_de_audicao === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Etilismo (Bebe)?', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Etilismo_bebe === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Etilismo_bebe === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Otite crônica, Zumbido no ouvido', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Otite_cronica_Zumbido_no_ouvido === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Otite_cronica_Zumbido_no_ouvido === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            { text: 'Se considera acima do peso?', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Se_considera_acima_do_peso === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Se_considera_acima_do_peso === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Dor de cabeça, Tontura, Convulsões', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Dor_de_cabeca_Tontura_Convulsoes === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Dor_de_cabeca_Tontura_Convulsoes === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            { text: 'Sofreu ou sofre de insônia?', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Sofreu_ou_sofre_de_insonia === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Sofreu_ou_sofre_de_insonia === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Doenças de pele', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Doencas_de_pele === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Doencas_de_pele === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Se considera estressado?', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Se_considera_estressado === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Se_considera_estressado === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Dores nas costas', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Dores_nas_costas === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Dores_nas_costas === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Pratica esporte regularmente?', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Pratica_esporte_regularmente === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Pratica_esporte_regularmente === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Reumatismo, Dor nas juntas', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Reumatismo_Dor_nas_juntas === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Reumatismo_Dor_nas_juntas === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Faz uso de medicamento?', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Faz_uso_de_medicamento === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Faz_uso_de_medicamento === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Sofreu alguma fratura óssea', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Sofreu_alguma_fratura_ossea === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Sofreu_alguma_fratura_ossea === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text: `Outra doença? (${dataKit.kit[0]?.Nome_doenca_2})`,
              colSpan: 2
            },
            {},
            {
              text: dataKit.kit[0]?.Outra_doenca === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Outra_doenca === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Doença psiquiátrica ', colSpan: 2 },
            {},
            {
              text: dataKit.kit[0]?.Doenca_Psiquiatricas === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit.kit[0]?.Doenca_Psiquiatricas === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Afastado por mais de 15 dias?', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Afastado_por_mais_de_15_dias === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Afastado_por_mais_de_15_dias === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            {
              text: 'DATA DA ÚLTIMA MENSTRUAÇÃO, CASO Sexo Feminino',
              colSpan: 3
            },
            {},
            {},
            {
              text: dataKit.kit[0]?.Data_da_ultima_mestruacao_caso_Sexo_Feminino
                ? maskDate(
                    dataKit.kit[0]?.Data_da_ultima_mestruacao_caso_Sexo_Feminino
                  )
                : '',
              colSpan: 5
            },
            {},
            {},
            {},
            {}
          ],

          [
            {
              text: 'Antecedentes Ocupacionais',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: 'Sofreu ou sofre alguma doença ou situação abaixo?',
              colSpan: 8,
              fillColor: '#88CBC5',
              fontSize: 9,
              alignment: 'center'
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Doença ou Situação',
              colSpan: 2,
              fillColor: '#818181',
              color: '#FFF'
            },
            {},
            {
              text: 'Sim',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Não',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Doença ou Situação',
              colSpan: 2,
              fillColor: '#818181',
              color: '#FFF'
            },
            {},
            {
              text: 'Sim',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Não',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            }
          ],
          [
            { text: 'Já sofreu acidente do trabalho?', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Ja_sofreu_acidente_trabalho === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Ja_sofreu_acidente_trabalho === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text: 'Recebeu indenização por acidente dotrabalho?',
              colSpan: 2
            },
            {},
            {
              text:
                dataKit.kit[0]?.Recebeu_indenizacao_acidente_trabalho === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Recebeu_indenizacao_acidente_trabalho === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            {
              text: 'Já apresentou alguma doença do trabalho ou profissional?',
              colSpan: 2
            },
            {},
            {
              text:
                dataKit.kit[0]
                  ?.Ja_apresentou_alguma_doenca_trabalho_profssional === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]
                  ?.Ja_apresentou_alguma_doenca_trabalho_profssional === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text: 'Já se submeteu a exame complementar anterior',
              colSpan: 2
            },
            {},
            {
              text:
                dataKit.kit[0]?.Ja_se_submeteu_exame_complementar_anterior ===
                'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Ja_se_submeteu_exame_complementar_anterior ===
                'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            {
              text: 'Já apresentou afastamento superior a 15 dias por doença dotrabalho?',
              colSpan: 2
            },
            {},
            {
              text:
                dataKit.kit[0]?.Ja_apresentou_afastamento_superior_a_15_dias ===
                'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Ja_apresentou_afastamento_superior_a_15_dias ===
                'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text: 'Suas condições de saúde exigem atenção especial?',
              colSpan: 2
            },
            {},
            {
              text:
                dataKit.kit[0]?.Suas_condicoes_de_saude_exigem === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Suas_condicoes_de_saude_exigem === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Já exerceu atividade com uso de EPI?', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Ja_exerceu_atividade_com_uso_de_EPI === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Ja_exerceu_atividade_com_uso_de_EPI === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            { text: 'Necessita de uso de EPI em suas atividades?', colSpan: 2 },
            {},
            {
              text:
                dataKit.kit[0]?.Necessita_de_uso_de_EPI_em_suas === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit.kit[0]?.Necessita_de_uso_de_EPI_em_suas === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],

          // Colunas de assinaturas
          [
            {
              text: 'Observações',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: `${
                dataKit.kit[0]?.observacoes ? dataKit.kit[0]?.observacoes : ''
              }\n\n\n\n\n`,
              colSpan: 8
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {}
          ],

          [
            {
              text: 'Assinatura',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],

          // medico
          [
            { text: '\n\n\n', colSpan: 3 },
            {},
            {},
            { text: '\n\n\n', colSpan: 3 },
            {},
            {},
            {
              text: '___/___/____',
              colSpan: 2,
              alignment: 'center',
              margin: [0, 15, 0, 0]
            },
            {}
          ],
          // label
          [
            {
              text: 'Médico Examinador',
              colSpan: 3,
              alignment: 'center',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true
            },
            {},
            {},
            {
              text: 'Colaborador',
              colSpan: 3,
              alignment: 'center',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true
            },
            {},
            {},
            {
              text: 'Data',
              colSpan: 2,
              alignment: 'center',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true
            },
            {}
          ]
        ]
      }
    }
  ]; */

  function Rodape(currentPage: string, pageCounf: string) {
    return [
      {
        text: `${currentPage} / ${pageCounf}`,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 20, 0] // left, top, right, bottom
      }
    ];
  }

  const docDefinitions: any = {
    info: {
      title: data[0].colaborador[0]?.nome_completo,
      subject: data[0].colaborador[0]?.nome_completo,
      creator: data[0].colaborador[0]?.nome_completo,
      producer: data[0].colaborador[0]?.nome_completo,
      keywords: data[0].colaborador[0]?.nome_completo,
      download: data[0].colaborador[0]?.nome_completo,
      filename: data[0].colaborador[0]?.nome_completo,
      downloadName: data[0].colaborador[0]?.nome_completo
    },
    pageSize: 'A4',
    pageMargins: [50, 50, 50, 40], // left, top, right, bottom
    header: [reportTitle],
    content: [details],
    fontSize: 5,
    footer: Rodape
  };
  docDefinitions.content.push({
    text: '',
    style: 'header'
    // pageBreak: 'after'
  });
  // docDefinitions.content.push(fichaClinica);

  pdfMake
    .createPdf(docDefinitions)
    .download(`ASO ARYZ - ${data[0].colaborador[0]?.nome_completo.replace('.',' ')}`);
  // pdfMake.createPdf(docDefinitions).open();

  return <div />;
};
