import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useAuth } from '@context/auth';
import { Link, useLocation } from 'react-router-dom';
import {
  TestTube,
  PersonArmsSpread,
  Buildings,
  Calendar,
  FirstAidKit,
  UserPlus,
  MagnifyingGlass
} from '@phosphor-icons/react';
import Router from '@routes/app.routes';
import clsx from 'clsx';
import farol from '@assets/farol.png';
import prancheta3 from '@assets/prancheta3.png';
import {
  ListItemText,
  ListItem,
  Typography,
  List,
  Toolbar,
  AppBar,
  CssBaseline,
  Drawer,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Collapse
} from '@material-ui/core';
import {
  Home,
  DateRange,
  Description,
  Group,
  MonetizationOn,
  AssignmentTurnedIn,
  ExitToApp,
  Notifications,
  ChatBubble,
  ChevronRight,
  ChevronLeft,
  SupervisorAccount,
  ExpandLess,
  ExpandMore,
  Apartment,
  Pages,
  Person,
  AssignmentInd,
  BusinessCenter,
  CompareArrows,
  FindInPage,
  Schedule,
  AccountBalance,
  Ballot
} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import EsocialIcon from '@assets/esocial.svg';
import {
  storageMenuCollapsGet,
  storageMenuCollapse
} from '@base/storage/storageMenuCollapse';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    background: '#FFF'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    background: '#80BFB4'
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    },
    background: '#80BFB4'
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#80BFB4'
  },
  // necessary for content to be below app bar
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3)
  },
  contentAdm: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    flex: 1
  },
  listItem: {
    background: '#FFF',
    alignItems: 'center',
    gap: 5,
    opacity: 0.5,
    width: 200,
    borderRadius: '0px 10px 10px 0px',
    marginBottom: 4,
    cursor: 'pointer',
    '&:hover': {
      background: '#FFF',
      opacity: 0.8
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  listItemMenor: {
    background: '#FFF',
    alignItems: 'center',
    gap: 5,
    opacity: 0.5,
    width: 50,
    borderRadius: '0px 10px 10px 0px',
    marginBottom: 4,
    '&:hover': {
      background: '#FFF',
      opacity: 0.8
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  link: {
    textDecoration: 'none',
    color: '#000'
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30
  },
  logoGrande: {
    width: 100,
    height: 100,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  logoMenor: {
    width: 50,
    height: 50,
    marginTop: 40,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  header: {
    color: '#000',
    display: 'flex',
    marginLeft: 'auto',
    gap: 10
  },
  textListItem: {
    color: '#000',
    fontWeight: 'bold'
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

export const DrawerHearder: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { signOut, user } = useAuth();
  const [openMenu, setOpenMenu] = useState(null);
  const [open, setOpen] = useState(true);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openEnterpriseCollapse, setOpenEnterpriseCollapse] = useState(false);
  const [openContractEnterpriseCollapse, setOpenContractEnterpriseCollapse] =
    useState(false);
  const [openAso, setOpenAso] = useState(false);
  const [schedleOpen, setSchedleOpen] = useState(false);
  const location = useLocation();

  const handleClick = (event: any) => {
    setOpenMenu(event.currentTarget);
  };

  const handleOpenCollapse = () => {
    setOpenCollapse(!openCollapse);
    storageMenuCollapse({
      schedule: schedleOpen,
      aso: openAso,
      enterpriseRh: !openCollapse
    });
    setOpen(true);
  };

  const handleOpenCollapseEnterprise = () => {
    setOpenEnterpriseCollapse(!openEnterpriseCollapse);
    
    setOpen(true);
  };

  function handleOpenCollapseAso() {
    setOpenAso(!openAso);
    storageMenuCollapse({
      schedule: schedleOpen,
      aso: !openAso,
      enterpriseRh: openEnterpriseCollapse
    });
    setOpen(true);
  }

  async function handleOpenCollapseSchedle() {
    setSchedleOpen(!schedleOpen);
    storageMenuCollapse({
      schedule: !schedleOpen,
      aso: openAso,
      enterpriseRh: openEnterpriseCollapse
    });

    setOpen(true);
  }

  function handleOpenContractEnterpriseCollapse() {
    setOpenContractEnterpriseCollapse(!openContractEnterpriseCollapse);
    setOpen(true);
  }

  const handleClose = () => {
    setOpenMenu(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenCollapse(false);
    setOpenEnterpriseCollapse(false);
    setOpenContractEnterpriseCollapse(false);
    setOpenAso(false);
  };

  async function loadingDataMenu() {
    try {
      const { schedule, aso, enterpriseRh } = await storageMenuCollapsGet();

      setSchedleOpen(schedule);
      setOpenAso(aso);
      setOpenCollapse(enterpriseRh);
    } catch (error) {}
  }

  useEffect(() => {
    loadingDataMenu();
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            edge="start"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.header}>
            <IconButton>
              <ChatBubble fontSize="small" />
            </IconButton>
            <IconButton>
              <Badge overlap="rectangular" color="primary">
                <Notifications fontSize="small" />
              </Badge>
            </IconButton>
            <IconButton onClick={handleClick}>
              <Typography>{user.nome}</Typography>
            </IconButton>
            <Menu
              style={{ marginTop: 40, marginRight: 50 }}
              anchorEl={openMenu}
              open={Boolean(openMenu)}
              onClose={handleClose}
            >
              <MenuItem>Status: Online</MenuItem>
              <MenuItem>
                Nível: {user.tipo_login === 'adm' ? 'Adm' : 'Funcioinário'}
              </MenuItem>
              <MenuItem>Account Setting</MenuItem>
              <MenuItem onClick={signOut}>Sair</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        /*  className={classes.drawer} */
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        anchor="left"
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRight style={{ color: '#FFF' }} />
            ) : (
              <ChevronLeft style={{ color: '#FFF' }} />
            )}
          </IconButton>
        </div>
        <div className={classes.logo}>
          <img
            src={prancheta3}
            className={clsx(classes.logoGrande, { [classes.logoMenor]: !open })}
          />
        </div>
        <List>
          {/* <Link to="/" className={classes.link}>
            <ListItem
              className={clsx(classes.listItem, {
                [classes.listItemMenor]: !open
              })}
            >
              <Home style={{ color: '#131313', fontSize: 25 }} />
              <ListItemText
                className={clsx(classes.textListItem, {
                  [classes.hide]: !open
                })}
              >
                Home
              </ListItemText>
            </ListItem>
          </Link> */}
          <ListItem
            onClick={handleOpenCollapseSchedle}
            className={clsx(classes.listItem, {
              [classes.listItemMenor]: !open
            })}
          >
            <Calendar style={{ color: '#131313', fontSize: 25 }} />
            <ListItemText
              className={clsx(classes.textListItem, {
                [classes.hide]: !open
              })}
            >
              Agendamento
            </ListItemText>
            {open ? schedleOpen ? <ExpandLess /> : <ExpandMore /> : ''}
          </ListItem>
          <Collapse in={schedleOpen} timeout="auto" unmountOnExit>
            <Link
              to={{ pathname: '/agendar', state: { status: open } }}
              className={classes.link}
            >
              <List component="div" disablePadding>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    { [classes.listItemMenor]: !open },
                    classes.nested
                  )}
                >
                  <UserPlus style={{ color: '#131313', fontSize: 25 }} />
                  <ListItemText
                    className={clsx(classes.textListItem, {
                      [classes.hide]: !open
                    })}
                  >
                    Cadastrar
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
          </Collapse>
          <Collapse in={schedleOpen} timeout="auto" unmountOnExit>
            <Link to="/agendamentos" className={classes.link}>
              <List component="div" disablePadding>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    { [classes.listItemMenor]: !open },
                    classes.nested
                  )}
                >
                  <MagnifyingGlass style={{ color: '#131313', fontSize: 25 }} />
                  <ListItemText
                    className={clsx(classes.textListItem, {
                      [classes.hide]: !open
                    })}
                  >
                    Consultar
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
          </Collapse>
          <Collapse in={schedleOpen} timeout="auto" unmountOnExit>
            <Link to="/agendamentos-wp" className={classes.link}>
              <List component="div" disablePadding>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    { [classes.listItemMenor]: !open },
                    classes.nested
                  )}
                >
                  <MagnifyingGlass style={{ color: '#131313', fontSize: 25 }} />
                  <ListItemText
                    className={clsx(classes.textListItem, {
                      [classes.hide]: !open
                    })}
                  >
                    Pré-Agedamento
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
          </Collapse>

          <ListItem
            onClick={handleOpenCollapseAso}
            className={clsx(classes.listItem, {
              [classes.listItemMenor]: !open
            })}
          >
            <FirstAidKit style={{ color: '#131313', fontSize: 25 }} />
            <ListItemText
              className={clsx(classes.textListItem, {
                [classes.hide]: !open
              })}
            >
              ASO
            </ListItemText>
            {open ? openAso ? <ExpandLess /> : <ExpandMore /> : ''}
          </ListItem>

          {/* <Collapse in={openAso} timeout="auto" unmountOnExit>
            <Link to="/agendamentos" className={classes.link}>
              <List component="div" disablePadding>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    { [classes.listItemMenor]: !open },
                    classes.nested
                  )}
                >
                  <Schedule style={{ color: '#131313', fontSize: 25 }} />
                  <ListItemText
                    className={clsx(classes.textListItem, {
                      [classes.hide]: !open
                    })}
                  >
                    Agendamento
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
          </Collapse> */}
          <Collapse in={openAso} timeout="auto" unmountOnExit>
            <Link to="/exames-a-vencer" className={classes.link}>
              <List component="div" disablePadding>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    { [classes.listItemMenor]: !open },
                    classes.nested
                  )}
                >
                  <TestTube style={{ color: '#131313', fontSize: 25 }} />
                  <ListItemText
                    className={clsx(classes.textListItem, {
                      [classes.hide]: !open
                    })}
                  >
                    Exames a vencer
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
          </Collapse>
          <Collapse in={openAso} timeout="auto" unmountOnExit>
            <Link to="/kit" className={classes.link}>
              <List component="div" disablePadding>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    { [classes.listItemMenor]: !open },
                    classes.nested
                  )}
                >
                  <FindInPage style={{ color: '#131313', fontSize: 25 }} />
                  <ListItemText
                    className={clsx(classes.textListItem, {
                      [classes.hide]: !open
                    })}
                  >
                    Kit
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
          </Collapse>
          <Collapse in={openAso} timeout="auto" unmountOnExit>
            <Link to="/e-social" className={classes.link}>
              <List component="div" disablePadding>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    { [classes.listItemMenor]: !open },
                    classes.nested
                  )}
                >
                  <img src={EsocialIcon} width={25} height={25} />
                  <ListItemText
                    className={clsx(classes.textListItem, {
                      [classes.hide]: !open
                    })}
                  >
                    E-social
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
          </Collapse>
          <Link to="/credenciados" className={classes.link}>
            <ListItem
              className={clsx(classes.listItem, {
                [classes.listItemMenor]: !open
              })}
            >
              <Description style={{ color: '#131313', fontSize: 25 }} />
              <ListItemText
                className={clsx(classes.textListItem, {
                  [classes.hide]: !open
                })}
              >
                Credenciados
              </ListItemText>
            </ListItem>
          </Link>
          <Link to="/colaboradores" className={classes.link}>
            <ListItem
              className={clsx(classes.listItem, {
                [classes.listItemMenor]: !open
              })}
            >
              <Person style={{ color: '#131313', fontSize: 25 }} />
              <ListItemText
                className={clsx(classes.textListItem, {
                  [classes.hide]: !open
                })}
              >
                Colaboradores
              </ListItemText>
            </ListItem>
          </Link>

          <ListItem
            onClick={handleOpenCollapse}
            className={clsx(classes.listItem, {
              [classes.listItemMenor]: !open
            })}
          >
            <Group style={{ color: '#131313', fontSize: 25 }} />
            <ListItemText
              className={clsx(classes.textListItem, {
                [classes.hide]: !open
              })}
            >
              Empresas-RH
            </ListItemText>
            {open ? openCollapse ? <ExpandLess /> : <ExpandMore /> : ''}
          </ListItem>

          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Link to="/empresa-rh" className={classes.link}>
              <List component="div" disablePadding>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    { [classes.listItemMenor]: !open },
                    classes.nested
                  )}
                >
                  <Buildings style={{ color: '#131313', fontSize: 25 }} />
                  <ListItemText
                    className={clsx(classes.textListItem, {
                      [classes.hide]: !open
                    })}
                  >
                    Empresa
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
          </Collapse>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Link to="/local-obra" className={classes.link}>
              <List component="div" disablePadding>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    { [classes.listItemMenor]: !open },
                    classes.nested
                  )}
                >
                  <Apartment style={{ color: '#131313', fontSize: 25 }} />
                  <ListItemText
                    className={clsx(classes.textListItem, {
                      [classes.hide]: !open
                    })}
                  >
                    Local/Obra
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
          </Collapse>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Link to="/ghe" className={classes.link}>
              <List component="div" disablePadding>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    { [classes.listItemMenor]: !open },
                    classes.nested
                  )}
                >
                  <Pages style={{ color: '#131313', fontSize: 25 }} />
                  <ListItemText
                    className={clsx(classes.textListItem, {
                      [classes.hide]: !open
                    })}
                  >
                    GHE
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
          </Collapse>

          <ListItem
            onClick={handleOpenCollapseEnterprise}
            className={clsx(classes.listItem, {
              [classes.listItemMenor]: !open
            })}
          >
            <MonetizationOn style={{ color: '#131313', fontSize: 25 }} />
            <ListItemText
              className={clsx(classes.textListItem, { [classes.hide]: !open })}
            >
              Financeiro
            </ListItemText>
            {open ? (
              openEnterpriseCollapse ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : (
              ''
            )}
          </ListItem>

          <Collapse in={openEnterpriseCollapse} timeout="auto" unmountOnExit>
            <Link to="/exames-empresas" className={classes.link}>
              <List component="div" disablePadding>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    { [classes.listItemMenor]: !open },
                    classes.nested
                  )}
                >
                  <BusinessCenter style={{ color: '#131313', fontSize: 25 }} />
                  <ListItemText
                    className={clsx(classes.textListItem, {
                      [classes.hide]: !open
                    })}
                  >
                    Exames Emp.
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
          </Collapse>
          <Collapse in={openEnterpriseCollapse} timeout="auto" unmountOnExit>
            <Link to="/exames-credenciados" className={classes.link}>
              <List component="div" disablePadding>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    { [classes.listItemMenor]: !open },
                    classes.nested
                  )}
                >
                  <AssignmentInd style={{ color: '#131313', fontSize: 25 }} />
                  <ListItemText
                    className={clsx(classes.textListItem, {
                      [classes.hide]: !open
                    })}
                  >
                    Exames Cred.
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
          </Collapse>
          <Collapse in={openEnterpriseCollapse} timeout="auto" unmountOnExit>
            <Link to="/comparativo-exames" className={classes.link}>
              <List component="div" disablePadding>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    { [classes.listItemMenor]: !open },
                    classes.nested
                  )}
                >
                  <CompareArrows style={{ color: '#131313', fontSize: 25 }} />
                  <ListItemText
                    className={clsx(classes.textListItem, {
                      [classes.hide]: !open
                    })}
                  >
                    Comparativo
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
          </Collapse>

          <ListItem
            className={clsx(classes.listItem, {
              [classes.listItemMenor]: !open
            })}
            onClick={handleOpenContractEnterpriseCollapse}
          >
            <AssignmentTurnedIn style={{ color: '#131313', fontSize: 25 }} />
            <ListItemText
              className={clsx(classes.textListItem, {
                [classes.hide]: !open
              })}
            >
              Contratos
            </ListItemText>
            {open ? (
              openContractEnterpriseCollapse ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : (
              ''
            )}
          </ListItem>

          <Collapse
            in={openContractEnterpriseCollapse}
            timeout="auto"
            unmountOnExit
          >
            <Link to="/contratos-empresa" className={classes.link}>
              <ListItem
                className={clsx(
                  classes.listItem,
                  { [classes.listItemMenor]: !open },
                  classes.nested
                )}
              >
                <AccountBalance style={{ color: '#131313', fontSize: 25 }} />
                <ListItemText
                  className={clsx(classes.textListItem, {
                    [classes.hide]: !open
                  })}
                >
                  Contratos Emp
                </ListItemText>
              </ListItem>
            </Link>
          </Collapse>
          <Collapse
            in={openContractEnterpriseCollapse}
            timeout="auto"
            unmountOnExit
          >
            <Link to="/contratos-credenciado" className={classes.link}>
              <List component="div" disablePadding>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    { [classes.listItemMenor]: !open },
                    classes.nested
                  )}
                >
                  <Ballot style={{ color: '#131313', fontSize: 25 }} />
                  <ListItemText
                    className={clsx(classes.textListItem, {
                      [classes.hide]: !open
                    })}
                  >
                    Contratos Cred
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
          </Collapse>

          {user.tipo_login === 'adm' ? (
            <Link to="/adm" style={{ textDecoration: 'none' }}>
              <ListItem
                className={clsx(classes.listItem, {
                  [classes.listItemMenor]: !open
                })}
              >
                <SupervisorAccount style={{ color: '#131313', fontSize: 25 }} />
                <ListItemText
                  className={clsx(classes.link, { [classes.hide]: !open })}
                >
                  Administrativo
                </ListItemText>
              </ListItem>
            </Link>
          ) : (
            ''
          )}
        </List>
      </Drawer>
      <main
        className={
          location.pathname === '/adm' ? classes.contentAdm : classes.content
        }
      >
        <div className={classes.toolbar} />
        <Router />
      </main>
    </div>
  );
};
