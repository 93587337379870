import styled from 'styled-components';
// import backgroundImage from '../../assets/orig.jpg';

import { Typography } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
 // 
export const ContainerSignIn = styled.main`
 
 background: rgb(139,217,195);
 background: linear-gradient(45deg, rgba(139,217,195,1) 0%, rgba(105,88,160,1) 36%, rgba(92,84,140,1) 100%);
  
  `;

export const TitleSignIn = styled(Typography).attrs({
  
  variant: 'h4',
  align: 'center'
})`
  && {
    margin: 25px 0 -25px;
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
  height: 100vh;
  margin-left: 5%;
`;

export const CarouselStyled = styled(Carousel).attrs({
  autoPlay: false,
  animation: 'slide',
  indicators: false,
  navButtonsAlwaysInvisible: true,
  swipe: false
})`
  justify-content: center;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  max-width: 100%;
  height: 6rem;
  /* margin-top: 1rem; */
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 2rem;
  margin-top: 8px;
`;

export const ContainerButtons = styled.div`
  width: auto;
  flex-direction: column;
  display: flex;
  gap: 5px;
  
`;
