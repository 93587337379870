import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { HighlightOff, SettingsBackupRestore, Add } from '@material-ui/icons';
import MuiAlert, { type Color } from '@material-ui/lab/Alert';
import { api } from '@services/api';

import { maskCPF, maskDate, verifyNumberOrString } from '@utils/masks';
import { AxiosError } from 'axios';
import { Button } from '@components/Button';
import { LoadingPage } from '@components/LoadingPage';
import Pagination from '@material-ui/lab/Pagination';
import usePagination from '@hooks/usePagination';
import { MagnifyingGlass } from '@phosphor-icons/react/dist/ssr';
import { status } from '@data/status';
import { Plus, X } from '@phosphor-icons/react';
import { AppError } from '@utils/AppError';

interface IAgenda {
  id_agendamento: number;
  nome: string;
  cpf_colaborador: string;
  tipo_agendamento: string;
  data_agendamento: string;
  horario: string;
  status: string;
  nome_comercial: string;
}

interface PropsResult {
  results: IAgenda[];
  total_pages: number;
}

export const AgenExterno: React.FC<IAgenda> = () => {
  const [pagination, setPagination] = useState<PropsResult>({} as PropsResult);
  const [agenda, setAgenda] = useState<IAgenda[]>([]);
  const [search, setSearch] = useState<string>('');
  const [messageEmpty, setMessageEmpty] = useState('');
  const [modalAction, setModalAction] = useState<boolean>(false);
  const [edit, setEdit] = useState<IAgenda | undefined>();
  const [errors, setError] = useState<AxiosError | null>(null);
  const [loading, setLoading] = useState(true);

  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState();
  const [cod, setCod] = useState();
  const [severity, setSeverity] = useState<Color>();
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [value, setValue] = useState('');

  const [filterState, setFilterState] = useState('');

  const [filterColab, setFilterColab] = useState<IAgenda[]>([]);

  const [page, setPage] = useState(1);
  const PER_PAGE = 30;

  const count = Math.ceil(pagination.total_pages);
  const _DATA = usePagination(pagination.results, PER_PAGE);

  const dataAgendamentos = _DATA.currentData();

  const filter = filterColab.length > 0 ? filterColab : dataAgendamentos;

  const fetchFilter = useCallback(
    async (value: string, status: string) => {
      try {
        setIsLoadingData(true);
        handleSearchFilter();

        const name = value.includes('.') ? '' : value.toUpperCase().trim();
        const state = status.toUpperCase().trim();
        const cpf = value.includes('.') ? value : '';

        const data = {
          nome: name,
          status: state,
          cpf
        };

        const response = await api.post('ravim/pesq-agenda', data);

        if (
          typeof response.data.length !== 'undefined' &&
          response.data.length > 0
        ) {
          setMessageEmpty('');
          return setFilterColab(response.data);
        }
        if (typeof response.data.length === 'undefined') {
          return setMessageEmpty('Registro não encontrado.');
        }
      } catch (error) {
        if (error instanceof AppError) {
          if (error.code === 404) {
            setFilterColab([]);
            setMessageEmpty(error.message);
          }
        }
      } finally {
        setIsLoadingData(false);
      }
    },
    [filterState, search]
  );

  async function reqAgenda() {
    try {
      const res = await api.get(`/ravim/agendamentos-wp?page=${page}`);
      if (res.status === 404) {
        return;
      }
      setPagination(res.data);
    } catch (error: any) {
      setError(error as AxiosError);
      setSeverity('error');
      setSnack(true);
      setMessage(error.response.statusText);
      setCod(error.response.status);
    } finally {
      setLoading(false);
    }
  }

  function deleteAgendamento(value: IAgenda) {
    setModalAction(true);
    setEdit(value);
  }

  const dialogClose = () => {
    setModalAction(false);
  };

  async function deleteAgenda(id_agendamento: number) {
    await api
      .delete(`/ravim/exclu-agendamento`, {
        data: { id_agendamento: String(id_agendamento) }
      })
      .then((res) => {
        if (res.status === 200) {
          setSnack(true);
          setModalAction(false);
          setMessage(res.data.message);
          setSeverity('success');
          reqAgenda();
        } else {
          setSeverity('error');
          setSnack(true);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleLoading() {
    setLoading(loading);
  }

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    reqAgenda();
  }, [page]);

  function handleClearFields() {
    setSearch('');
    setFilterState('');
    setValue('');
    setFilterColab([]);
    setMessageEmpty('');
  }

  function handleSearchFilter() {
    setSearch(value);
  }

  return (
    <Container>
      <LoadingPage open={loading} onClick={handleLoading} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 20,
          marginBottom: 20,
          alignItems: 'center'
        }}
      >
        <TextField
          label="Pesquisar agendamento"
          type="text"
          variant="outlined"
          value={value}
          inputProps={{ maxLength: verifyNumberOrString(value) ? 14 : null }}
          onChange={(e) => {
            if (verifyNumberOrString(e.target.value)) {
              setValue(maskCPF(e.target.value));
            } else {
              setValue(e.target.value);
            }
          }}
        />
        <FormControl variant="outlined" style={{ width: '20%' }}>
          <InputLabel>Status</InputLabel>
          <Select
            label="Status"
            value={filterState}
            onChange={(e: any) => {
              setFilterState(e.target.value);
            }}
          >
            {status.map((status, index) => (
              <MenuItem key={status.value} value={status.value}>
                {status.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variantstyle="SECUNDARY"
          isLoading={isLoadingData}
          icon={<MagnifyingGlass size={20} />}
          disabled={!value && !filterState}
          onClick={() => {
            fetchFilter(value, filterState);
          }}
        />
        {!!messageEmpty || value || filterState ? (
          <Button
            variantstyle={'RED'}
            icon={<X size={20} />}
            onClick={handleClearFields}
          />
        ) : (
          <></>
        )}
      </div>

      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Agendamentos Externo
      </Typography>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 20
        }}
      >
        {!messageEmpty ? (
          <>
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />

            <TableContainer component={Paper}>
              <Table
                style={{
                  minWidth: 650,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Colaborador</TableCell>
                    <TableCell style={{width:'15%', textAlign:'center'}}>CPF</TableCell>
                    <TableCell>Nome Comercial</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Data do Agendamento</TableCell>
                    <TableCell>Horário</TableCell>
                    <TableCell>Tipo de agendamento</TableCell>
                    <TableCell>ATUALIZAR</TableCell>
                    <TableCell>DELETAR</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filter &&
                    filter.map((agenda: IAgenda, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{agenda.nome}</TableCell>
                          <TableCell style={{textAlign:'center'}}>
                            {maskCPF(agenda.cpf_colaborador)}
                          </TableCell>
                          <TableCell>{agenda.nome_comercial}</TableCell>
                          <TableCell>{agenda.status}</TableCell>
                          <TableCell>
                            {agenda.data_agendamento.replace(
                              /(\d*)-(\d*)-(\d*).*/,
                              '$3/$2/$1'
                            )}
                          </TableCell>
                          <TableCell>{agenda.horario}</TableCell>
                          <TableCell>{agenda.tipo_agendamento}</TableCell>

                          <TableCell>
                            <Link
                              to={{
                                pathname: `/editar-agendamento`,
                                state: agenda
                              }}
                            >
                              <IconButton>
                                <SettingsBackupRestore
                                  fontSize="medium"
                                  style={{ color: '#422C59' }}
                                />
                              </IconButton>
                            </Link>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                deleteAgendamento(agenda);
                              }}
                            >
                              <HighlightOff
                                fontSize="medium"
                                style={{ color: 'red' }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </>
        ) : (
          <Typography>{messageEmpty}</Typography>
        )}
      </div>

      <Dialog open={modalAction} onClose={dialogClose}>
        <DialogTitle>Agendamento a ser deletado, confira os dados</DialogTitle>
        <DialogContent>
          <Typography>
            Nome: <b>{edit?.nome}</b>
          </Typography>
          <Typography>
            Data do agendamento:{' '}
            <b>
              {edit?.data_agendamento.replace(
                /(\d*)-(\d*)-(\d*).*/,
                '$3/$2/$1'
              )}
            </b>
          </Typography>
          <Typography>
            Horário do agendamento: <b>{edit?.horario}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            title="Cancelar"
            variantstyle="DEFAULT"
            onClick={dialogClose}
          />

          <Button
            variantstyle="SECUNDARY"
            autoFocus
            title="Deletar"
            onClick={async () => {
              await deleteAgenda(edit?.id_agendamento!);
            }}
          />
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert severity={severity} variant="filled">
          {cod} {message}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};
