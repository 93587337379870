import React, { useCallback, useEffect, useState } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { maskCEP, maskCPF, maskDate } from '../../../utils/masks';

interface IClinica {
  id_clinicas_credenciadas: number;
  contrato: string;
  nome_comercial: string;
  razao_social: string;
  cpf_cnpj: string;
  dias_funcionamento: string;
  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  nome_med: string;
  crm: string;
  crm_estado: string;
  trabalho_agendamento: string;
  pagamento_adiantado: string;
  telemedicina: string;
  observacao: string;
}

interface IAgendamento {
  cidade: string;
  cpf_cnpj_comer: string;
  cpf_colaborador: string;
  data_agendamento: string;
  estado: string;
  horario: string;
  id_agendamento: number;
  nome: string;
  nome_comercial: string;
  num_agenda: string;
  observacao: string;
  ordem_chegada: string;
  status: string;
  telemedicina: string;
  tipo_aso: string;
  usuario: string;
}

interface IColaborador {
  id_colaboradores: number;
  matricula_empresa: string;
  matricula_esocial: string;
  nome_completo: string;
  cpf_colaborador: string;
  rg: string;
  data_nascimento: string;
  genero: string;
  cnh: string;
  email: string;
  telefone: string;
  situacao: string;
  nis: string;
  funcao: string;
  setor: string;
  turno: string;
  cnpj: string;
  razao_social: string;
  data_admissao: string;
  horario_trab_entrada: string;
  horario_trab_saida: string;
  local_obra: string;
  cod_ghe: string;
  ghe: string;
}

interface IEmpresaColab {
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  estado: string;
  numero: string;
  rua: string;
}

interface ITelefoneEmpresa {
  cnpj: string;
  id_empresa_telefone: number;
  telefone: string;
}

interface IExameGHE {
  id_exame_ghe: number;
  cod_ghe: string;
  exame_empresa: string;
  dias: string;
  data: [
    {
      data_agendamento: string;
    }
  ];
}

interface IGhe {
  cod_ghe: string;
  cod_obra: string;
  crm: string;
  crm_estado: string;
  exibir: string;
  id_cad_ghe: number;
  nome_ghe: string;
  nome_med: string;
  obs_aso: string;
  tipo_plano: string;
  valor_plano: string;
}

interface IRisco_Ghe {
  id_risco_ghe: number;
  cod_ghe: string;
  nome: string;
}

interface ITelefone_Credenciado {
  telefone: string;
}

interface Exame {
  exame_empresa: string;
  valor_empresa: string;
}

export interface Credenciado {
  cpf_cnpj_comer: string;
  data_agendamento: string;
  exames: ExameCredenciado[];
  horario: string;
  id_exame_hibrido: number;
  linkDoatendimento: string;
  nome_comercial: string;
  num_agenda: string;
  ordem_chegada: string;
  tipo: string;
  tipo_agendamento: string;
}

export interface ExameCredenciado {
  exame_credenciado: string;
  valor_credenciado: string;
}

interface IExamesToxico {
  cpf: string;
  exame_credenciado: string;
  exame_empresa: string;
  id_exame_colab: number;
  num_agenda: string;
  valor_credenciado: string;
  valor_empresa: string;
  data: [
    {
      data_agendamento: string;
    }
  ];
}

interface IPdf {
  clinica: IClinica[];
  agendamento: IAgendamento[];
  colaborador: IColaborador[];
  exames_ghe: IExameGHE[];
  ghe: IGhe[];
  risco_ghe: IRisco_Ghe[];
  empresa_colab: IEmpresaColab[];
  telefone_empresa: ITelefoneEmpresa[];
  telefone_credenciado: ITelefone_Credenciado[];
  exames_hibrido: Credenciado[];
  exames_toxi: IExamesToxico[];
}

export const GuiaSingleExame = (
  data: IPdf[],
  id: number,
  tipoAtendimento: string
) => {
  const mapExames = data[0].exames_hibrido[id].exames;

  // Aqui divide em duas colunas os exames-hibrido
  const numberOfColumnsExamesHibrido = 2;
  const numberOfRowsExamesHibrido = Math.ceil(
    mapExames.length / numberOfColumnsExamesHibrido
  );
  const leftColumnExamesHibrido = [];
  const rightColumnExamesHibrido = [];
  const leftLengthExamesHibrido = [];

  for (let i = 0; i < numberOfRowsExamesHibrido; i++) {
    leftColumnExamesHibrido[i] = mapExames[i * numberOfColumnsExamesHibrido];
    rightColumnExamesHibrido[i] =
      mapExames[i * numberOfColumnsExamesHibrido + 1];
    leftLengthExamesHibrido[i] = i;
  }

  const rightExamesHibrido = rightColumnExamesHibrido.map((right, i) => {
    if (right !== undefined) {
      return [
        {
          text: right.exame_credenciado ? right.exame_credenciado : '',
          colSpan: 2,
          border: [true, true, false, true]
        },
        {},
        {
          text: data[0].exames_hibrido[id].data_agendamento
            ? maskDate(data[0].exames_hibrido[id].data_agendamento)
            : '',
          border: [false, true, true, true],
          bold: true
        }
      ];
    } else {
      return [
        { text: '', colSpan: 2, border: [true, true, false, true] },
        {},
        { text: `${''}`, border: [false, true, true, true], bold: true }
      ];
    }
  });

  const leftExamesHibrido = leftColumnExamesHibrido.map((left, i) => {
    if (left !== undefined) {
      return [
        {
          text: left.exame_credenciado ? left.exame_credenciado : '',
          colSpan: 2,
          border: [true, true, false, true]
        },
        {},
        {
          text: data[0].exames_hibrido[id].data_agendamento
            ? maskDate(data[0].exames_hibrido[id].data_agendamento)
            : '',
          border: [false, true, true, true],
          bold: true
        }
      ];
    } else {
      return [
        { text: '', colSpan: 2, border: [true, true, false, true] },
        {},
        { text: `${''}`, border: [false, true, true, true], bold: true }
      ];
    }
  });

  const countExamesExamesHibrido = leftLengthExamesHibrido.map((a, i) => [
    ...leftExamesHibrido[i],
    ...rightExamesHibrido[i]
  ]); // organiza por index as linhas da coluna de exames

  //= ===============================Fim da logica de exames hibrido ======================================================
  const selectTypeAttempt = () => {
    if (data[0].exames_hibrido[id]?.tipo_agendamento === 'telemedicina') {
      return [
        [
          {
            text: 'Clínica',
            fillColor: '#8c8c8c',
            color: '#000',
            bold: true,
            alignment: 'center'
          },
          {
            text: data[0].exames_hibrido[id]?.nome_comercial,
            colSpan: 2
          },
          {},
          {
            text: 'Atendimento',
            fillColor: '#8c8c8c',
            color: '#000',
            bold: true,
            alignment: 'center'
          },
          { text: data[0].clinica[0]?.dias_funcionamento, colSpan: 2 },
          {}
        ]
      ];
    } else {
      return [
        [
          {
            text: 'Clínica',
            fillColor: '#8c8c8c',
            color: '#000',
            bold: true,
            alignment: 'center'
          },
          {
            text: data[0].exames_hibrido[id]?.nome_comercial,
            colSpan: 2
          },
          {},
          {
            text: 'Atendimento',
            fillColor: '#8c8c8c',
            color: '#000',
            bold: true,
            alignment: 'center'
          },
          { text: data[0].clinica[0]?.dias_funcionamento, colSpan: 2 },
          {}
        ],
        [
          {
            text: 'Endereço',
            fillColor: '#8c8c8c',
            color: '#000',
            bold: true,
            alignment: 'center'
          },
          {
            text: `${data[0].clinica[0]?.rua}, ${data[0].clinica[0]
              ?.numero}, ${data[0].clinica[0]?.complemento}, ${data[0]
              .clinica[0]?.bairro} - ${data[0].clinica[0]?.estado}, ${data[0]
              .clinica[0]?.cidade}, ${
              data[0].clinica[0]?.cep
                ? maskCEP(data[0].clinica[0]?.cep)
                : data[0].clinica[0]?.cep
            } `,
            colSpan: 2
          },
          {},
          {
            text: 'Tel',
            fillColor: '#8c8c8c',
            color: '#000',
            bold: true,
            alignment: 'center'
          },
          { text: data[0].telefone_credenciado[0]?.telefone, colSpan: 2 },
          {}
        ]
      ];
    }
  };

  const linkDoAtendimento = () => {
    if (data[0].exames_hibrido[id]?.tipo_agendamento === 'telemedicina') {
      return [
        {
          text: 'Resp. do PCMSO',
          fillColor: '#8c8c8c',
          color: '#000',
          bold: true,
          alignment: 'center',
          fontSize: 9
        },
        { text: data[0].ghe[0]?.nome_med },
        {
          text: 'CRM',
          fillColor: '#8c8c8c',
          color: '#000',
          bold: true,
          alignment: 'center'
        },
        { text: `${data[0].ghe[0]?.crm}/${data[0].ghe[0]?.crm_estado}` },
        {
          text: 'Link do atendimento',
          fillColor: '#8c8c8c',
          color: '#000',
          bold: true,
          alignment: 'center'
        },
        { text: data[0]?.exames_hibrido[id].linkDoatendimento }
      ];
    } else {
      return [
        {
          text: 'Resp. do PCMSO',
          fillColor: '#8c8c8c',
          color: '#000',
          bold: true,
          alignment: 'center',
          fontSize: 9
        },
        { text: data[0].ghe[0]?.nome_med, colSpan: 3 },
        {},
        {},
        {
          text: 'CRM',
          fillColor: '#8c8c8c',
          color: '#000',
          bold: true,
          alignment: 'center'
        },
        { text: `${data[0].ghe[0]?.crm}/${data[0].ghe[0]?.crm_estado}` }
      ];
    }
  };

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      text: '',
      fontSize: 15,
      bold: true,
      margin: [30, 30, 0, 45] // left, top, right, bottom
    }
  ];
  const details = [
    {
      style: { fontSize: 10 },
      color: '#444',
      table: {
        widths: [80, 120, 60, 80, '*', 80],
        // widths: ['*', '*', '*', '*', '*', '*'],
        headerRows: 2,
        body: [
          [
            {
              text: [
                {
                  text:
                    data[0].exames_hibrido[id]?.tipo_agendamento ===
                    'telemedicina'
                      ? String(
                          'Guia de encaminhamento telemedicina'
                        ).toLocaleUpperCase()
                      : String(
                          'Guia de encaminhamento presencial'
                        ).toLocaleUpperCase(),
                  style: 'tableHeader',
                  fontSize: 18,
                  alignment: 'center'
                },
                {
                  text: '\n(11) 96621 8866 - www.ravim.health - agendamento@ravim.health',
                  alignment: 'center'
                }
              ],
              colSpan: 6,
              color: '#5d4c71'
            },
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Dados do Prestador de Serviço',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#8c8c8c',
              color: '#FFF',
              bold: true
            },
            {},
            {},
            {},
            {},
            {}
          ],
          ...selectTypeAttempt(),

          [
            {
              text: 'Dados da Empresa',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#8c8c8c',
              color: '#FFF',
              bold: true
            },
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Empresa',
              fillColor: '#8c8c8c',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0]?.razao_social, colSpan: 2 },
            {},
            {
              text: 'CNPJ',
              fillColor: '#8c8c8c',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text:
                data[0].exames_hibrido[id]?.tipo_agendamento === 'telemedicina'
                  ? data[0]?.exames_hibrido[id]?.cpf_cnpj_comer
                  : data[0]?.exames_hibrido[id]?.cpf_cnpj_comer,
              colSpan: 2
            },
            {}
          ],

          [
            {
              text: 'Dados do Colaborador',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#8c8c8c',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: 'Colaborador',
              fillColor: '#8c8c8c',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0]?.nome_completo, colSpan: 5 }
          ],
          [
            {
              text: 'Setor',
              fillColor: '#8c8c8c',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0]?.setor },
            {
              text: 'Função',
              fillColor: '#8c8c8c',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0]?.funcao, colSpan: 3 }
          ],
          [
            {
              text: 'CPF',
              fillColor: '#8c8c8c',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].colaborador[0].cpf_colaborador
                ? maskCPF(data[0].colaborador[0]?.cpf_colaborador)
                : data[0].colaborador[0]?.cpf_colaborador
            },
            {
              text: 'RG ou CTPS',
              fillColor: '#8c8c8c',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0]?.rg, colSpan: 3 }
          ],
          [
            {
              text: 'Nascimento',
              fillColor: '#8c8c8c',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: maskDate(data[0]?.colaborador[0].data_nascimento) },
            {
              text: 'GHE',
              fillColor: '#8c8c8c',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0].ghe.split('/')[1] },
            {
              text: 'Sexo',
              fillColor: '#8c8c8c',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0]?.genero }
          ],

          [
            {
              text: 'Dados do Agendamento',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#8c8c8c',
              color: '#FFF',
              bold: true
            }
          ],
          linkDoAtendimento(),
          [
            {
              text: 'Tipo de ASO',
              fillColor: '#8c8c8c',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0].agendamento[0]?.tipo_aso },
            {
              text: 'Data',
              fillColor: '#8c8c8c',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].exames_hibrido[id].data_agendamento
            },
            {
              text: 'Horário',
              fillColor: '#8c8c8c',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0].exames_hibrido[id].horario }
          ],

          [
            {
              text: 'Exames Complementares a Serem Realizados',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#8c8c8c',
              color: '#FFF',
              bold: true
            }
          ],
          ...countExamesExamesHibrido,

          [
            {
              text: 'Observações',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#8c8c8c',
              color: '#FFF',
              bold: true
            }
          ],
          // Observaçõe aso
          [
            { text: data[0].agendamento[0]?.observacao, colSpan: 6, rowSpan: 3 }
          ],
          [{}, {}, {}, {}, {}, {}],
          [{}, {}, {}, {}, {}, {}]
        ]
      }
    }
  ];

  function Rodape(currentPage: string, pageCounf: string) {
    return [
      {
        text: `${currentPage} / ${pageCounf}`,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 20, 0] // left, top, right, bottom
      }
    ];
  }

  const docDefinitions: any = {
    info: {
      title: data[0].colaborador[0].nome_completo,
      subject: data[0].colaborador[0].nome_completo,
      creator: data[0].colaborador[0].nome_completo,
      producer: data[0].colaborador[0].nome_completo,
      keywords: data[0].colaborador[0].nome_completo,
      download: data[0].colaborador[0].nome_completo,
      filename: data[0].colaborador[0].nome_completo,
      downloadName: data[0].colaborador[0].nome_completo
    },
    pageSize: 'A4',
    pageMargins: [38, 50, 50, 40], // left, top, right, bottom
    header: [reportTitle],
    content: [details],
    fontSize: 5,
    footer: Rodape
  };

  pdfMake
    .createPdf(docDefinitions)
    .download(`GUIA ARYZ - ${data[0].colaborador[0].nome_completo.replace('.',' ')}`);
  // pdfMake.createPdf(docDefinitions).open();

  return <div />;
};
