import {
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import { HighlightOff, SettingsBackupRestore } from '@material-ui/icons';
import { type Color, Pagination } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { api } from '@services/api';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { maskCNPJ } from '@utils/masks';
import { Button } from '@components/Button';
import usePagination from '@hooks/usePagination';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { ContainerButtonsTop, DivSearch } from './styles';
import { NavLink } from '@components/NavLink';

interface IInfos {
  idcad_empresa: number;
  num_cnpj: string;
  nome_empresa: string;
  razao_social: string;
  nome_fantacia: string;
  rua: string;
  numero: string;
  cep: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  nome_contrato: string;
  cod_contrato: string;
  exibir: string;
}

const Empresas: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [rhempresa, setRhEmpresa] = useState<IInfos[]>([]);

  const [dialogDelete, setDialogDelete] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [edit, setEdit] = useState<IInfos>();
  const [snackErro, setSnackErro] = useState(false);
  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [cod, setCod] = useState('');
  const [severity, setSeverity] = useState<Color | undefined>();
  const [search, setSearch] = useState<string>('');

  const [value, setValue] = useState('');

  const [page, setPage] = useState(1);
  const PER_PAGE = 30;

  const count = Math.ceil(rhempresa.length / PER_PAGE);
  const _DATA = usePagination(rhempresa, PER_PAGE);

  const filterEmpresaPerPage =
    _DATA.currentData().length > 0
      ? _DATA
          .currentData()
          .filter(
            (contrato: IInfos) =>
              contrato.nome_empresa
                ?.toUpperCase()
                .includes(search?.toUpperCase()) ||
              contrato.num_cnpj?.toUpperCase().includes(search?.toUpperCase())
          )
      : [];

  const filterEmpresa =
    rhempresa.length > 0
      ? rhempresa.filter(
          (contrato: IInfos) =>
            contrato.nome_empresa
              ?.toUpperCase()
              .includes(search?.toUpperCase()) ||
            contrato.num_cnpj?.toUpperCase().includes(search?.toUpperCase()) ||
            contrato.cod_contrato?.toUpperCase().includes(search?.toUpperCase())
        )
      : [];

  const filter = search.length > 0 ? filterEmpresa : filterEmpresaPerPage;

  async function reqRh() {
    try {
      setLoading(true);
      const res = await api.get(`/ravim/empresas-rh`);
      if (res.status === 404) return;
      setRhEmpresa(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  function closeDialog() {
    setDialog(false);
    setDialogDelete(false);
  }

  function deleteContract(value: IInfos) {
    setDialogDelete(true);
    setEdit(value);
  }

  async function deleteContrato(cod_cont: any) {
    await api
      .delete(`/ravim/excluEmpresa`, { data: { num_cnpj: cod_cont } })
      .then((res) => {
        if (res.status === 200) {
          const newListEnterprise = rhempresa.filter(
            (item: IInfos) => item.num_cnpj !== cod_cont
          );
          setSnack(true);
          setDialogDelete(false);
          setMessage(res.data.message);
          setSeverity('success');
          setRhEmpresa(newListEnterprise);
        } else {
          setSeverity('error');
          setSnack(true);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  useEffect(() => {
    reqRh();
  }, []);

  useEffect(() => {
    if (!value) {
      setSearch('');
    }
  }, [value]);

  return (
    <Container>
      <ContainerButtonsTop>
        <DivSearch>
          <TextField
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            label="Pesquisar empresas"
            type="text"
            variant="outlined"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch(value);
              }
            }}
          />
          <Button
            variantstyle="SECUNDARY"
            icon={<MagnifyingGlass size={20} />}
            onClick={() => {
              handleSearch(value);
            }}
          />
        </DivSearch>
        <NavLink to="/cadastro-empresas">
          <Button title="Cadastro de contratos de Empresas" />
        </NavLink>
      </ContainerButtonsTop>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Empresas
      </Typography>
      <Typography variant="h6" align="left">
        Total: {rhempresa.length}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 20
        }}
      >
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
        {loading ? (
          <TableContainer component={Paper}>
            <Table style={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Numero do CNPJ</TableCell>
                  <TableCell>Nome Comercial</TableCell>
                  <TableCell>Código do Contrato</TableCell>
                  <TableCell>Cidade e Estado</TableCell>
                  <TableCell>ATUALIZAR</TableCell>
                  <TableCell>DELETAR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filter &&
                  filter.map((rh: IInfos, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{maskCNPJ(rh.num_cnpj)}</TableCell>
                      <TableCell>{rh.nome_empresa}</TableCell>
                      <TableCell>{rh.cod_contrato}</TableCell>
                      <TableCell>{`${rh.cidade} - ${rh.estado}`}</TableCell>

                      <TableCell>
                        <Link to={{ pathname: '/edit-empresa-rh', state: rh }}>
                          <IconButton>
                            <SettingsBackupRestore
                              fontSize="medium"
                              style={{ color: '#422C59' }}
                            />
                          </IconButton>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            deleteContract(rh);
                          }}
                        >
                          <HighlightOff
                            fontSize="medium"
                            style={{ color: 'red' }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress />
          </div>
        )}
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </div>
      <Dialog open={dialogDelete} onClose={closeDialog}>
        <DialogTitle>
          Contrato a ser deletado, confira o nome e código
        </DialogTitle>
        <DialogContent>
          <Typography>
            Nome: <b>{edit?.nome_fantacia}</b>
          </Typography>
          <Typography>
            Código: <b>{edit?.num_cnpj}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            title="Cancelar"
            onClick={closeDialog}
            variantstyle="SECUNDARY"
          />
          <Button
            title="Deletar"
            variantstyle="RED"
            autoFocus
            onClick={async () => {
              await deleteContrato(edit?.num_cnpj);
            }}
          />
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
};

export default Empresas;
