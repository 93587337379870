import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { maskCNPJ, maskCPF, maskDate } from '@utils/masks';
import { type KitProps } from '@dtos/kitDTO';

export const FichaClinica = (dataKit: KitProps[]) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const sex =  dataKit[0].colaborador[0]?.genero.substring(0,1).toLowerCase() === 'f' ? 'Seu esposo' : 'Sua esposa'
  
  const reportTitle = [
    {
      text: '',
      fontSize: 15,
      bold: true,
      margin: [15, 30, 0, 45] // left, top, right, bottom
    }
  ];

  const fichaClinica = [
    {
      style: { fontSize: 9 },
      // margin:[10, 8, 50, 40], //[left, top, right, bottom]
      color: '#444',
      table: {
        // widths: [80, 120, 60, '*', 80, 60],
        widths: [100, '*', 25, 25, '*', 50, 25, 25],
        headerRows: 2,
        body: [
          [
            {
              text: [
                {
                  text: String('FICHA CLÍNICA OCUPACIONAL').toUpperCase(),
                  style: 'tableHeader',
                  fontSize: 18,
                  alignment: 'center'
                },
                {
                  text: '\n(11) 96621 8866 - www.aryz.com.br - agendamento@aryz.com.br ',
                  alignment: 'center'
                }
              ],
              colSpan: 8,
              color: '#6D539E'
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Dados da Empresa',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Empresa',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: dataKit[0].colaborador[0]?.razao_social
                ? dataKit[0].colaborador[0]?.razao_social
                : '',
              colSpan: 3
            },
            {},
            {},
            {
              text: 'CNPJ',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: dataKit[0].colaborador[0]?.cnpj
                ? dataKit[0].colaborador[0]?.cnpj
                : '',
              colSpan: 3
            },
            {},
            {}
          ],
          /* [{ text: 'Endereço', fillColor: '#88CBC5', color: '#000', bold: true, alignment: 'center' }, { text: `${dataKit[0].clinica[0].bairro} - ${dataKit[0].clinica[0].cidade} - ${dataKit[0].clinica[0].estado} - ${dataKit[0].clinica[0].complemento} - \n${dataKit[0].clinica[0].cep}`, colSpan: 3 }, {},{}, { text: 'Tel', fillColor: '#88CBC5', color: '#000', bold: true, alignment: 'center' }, { text: dataKit[0].colaborador[0].telefone, colSpan: 3 }, {}, {}], */

          [
            {
              text: 'Dados do Colaborador',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: 'Colaborador',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: dataKit[0].colaborador[0]?.nome_completo
                ? dataKit[0].colaborador[0]?.nome_completo
                : '',
              colSpan: 7
            },
            {},
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Setor',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: dataKit[0].colaborador[0]?.setor
                ? dataKit[0].colaborador[0]?.setor
                : ''
            },
            {
              text: 'Função',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center',
              colSpan: 2
            },
            {},
            {
              text: dataKit[0].colaborador[0]?.funcao
                ? dataKit[0].colaborador[0]?.funcao
                : '',
              colSpan: 4
            },
            {},
            {},
            {}
          ],
          [
            {
              text: 'CPF',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: dataKit[0].colaborador[0]?.cpf_colaborador
                ? dataKit[0].colaborador[0]?.cpf_colaborador
                : ''
            },
            {
              text: 'RG ou CTPS',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center',
              colSpan: 2
            },
            {},
            {
              text: dataKit[0].colaborador[0]?.rg
                ? dataKit[0].colaborador[0]?.rg
                : '',
              colSpan: 4
            },
            {},
            {},
            {}
          ],
          [
            {
              text: 'Nascimento',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: dataKit[0].colaborador[0]?.data_nascimento
                ? maskDate(dataKit[0].colaborador[0]?.data_nascimento)
                : ''
            },
            {
              text: 'GHE',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center',
              colSpan: 2
            },
            {},
            {
              text: dataKit[0].colaborador[0]?.setor
                ? dataKit[0].colaborador[0]?.setor
                : ''
            },
            {
              text: 'Sexo',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: dataKit[0].colaborador[0]?.genero, colSpan: 2 },
            {}
          ],

          [
            {
              text: 'Dados do Agendamento',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: 'Resp. do PCMSO',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center',
              fontSize: 9
            },
            {
              text: dataKit[0].ghe[0]?.nome_med
                ? dataKit[0].ghe[0]?.nome_med
                : '',
              colSpan: 3
            },
            {},
            {},
            {
              text: 'CRM',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: `${dataKit[0].ghe[0]?.crm ? dataKit[0].ghe[0]?.crm : ''}/${
                dataKit[0].ghe[0]?.crm_estado
                  ? dataKit[0].ghe[0]?.crm_estado
                  : ''
              }`,
              colSpan: 3
            },
            {},
            {}
          ],

          [
            {
              text: 'Levantamento de Dados e Antecendentes Familiares',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: 'Peso (kg)',
              fillColor: '#88CBC5',
              aligment: 'center',
              margin: [0, 5, 10, 0],
              fontSize: 12,
              bold: true
            },
            { text: dataKit[0] ? dataKit[0].kit[0].peso : '', colSpan: 2 },
            {},
            {
              text: 'Altura (m)',
              fillColor: '#88CBC5',
              aligment: 'center',
              margin: [0, 5, 10, 0],
              fontSize: 12,
              bold: true,
              colSpan: 2
            },
            {},
            { text: dataKit[0] ? dataKit[0].kit[0]?.altura : '', colSpan: 3 },
            {},
            {}
          ],
          [
            {
              text: `${sex} ou qualquer um de seus familiares (pai, mãe, irmãos, mesmos que falecidos) tem ou tiveram algumas das doenças abaixo?`,
              colSpan: 8,
              fillColor: '#88CBC5',
              fontSize: 9,
              alignment: 'center'
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {}
          ],

          [
            {
              text: 'Doença',
              style: 'tableHeader',
              fillColor: '#818181',
              color: '#FFF'
            },
            { text: 'Parentesco', fillColor: '#818181', color: '#FFF' },
            {
              text: 'Sim',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Não',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            { text: 'Doença', fillColor: '#818181', color: '#FFF' },
            { text: 'Parentesco', fillColor: '#818181', color: '#FFF' },
            {
              text: 'Sim',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Não',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            }
          ],
          [
            { text: 'Diabetes', width: 1000 },
            { text: dataKit[0].kit[0]?.Parentesco_1 },
            {
              text: dataKit[0].kit[0]?.Diabetes_1 === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Diabetes_1 === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Cancêr' },
            { text: dataKit[0].kit[0]?.Parentesco_5 },
            {
              text: dataKit[0].kit[0]?.Cancer === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Cancer === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Doença do Coração' },
            { text: dataKit[0].kit[0]?.Parentesco_2 },
            {
              text: dataKit[0].kit[0]?.Doenca_do_Coracao === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Doenca_do_Coracao === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Pressão Alta' },
            { text: dataKit[0].kit[0]?.Parentesco_6 },
            {
              text: dataKit[0].kit[0]?.Pressao_Alta === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Pressao_Alta === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Doença Psiquiátricas' },
            { text: dataKit[0].kit[0]?.Parentesco_3 },
            {
              text:
                dataKit[0].kit[0]?.Doenca_Psiquiatricas === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Doenca_Psiquiatricas === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Alergias' },
            { text: dataKit[0].kit[0]?.Parentesco_7 },
            {
              text: dataKit[0].kit[0]?.Alergias === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Alergias === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Tuberculose' },
            { text: dataKit[0].kit[0]?.Parentesco_4 },
            {
              text: dataKit[0].kit[0]?.Tuberculose === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Tuberculose === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: `Outras: ${dataKit[0].kit[0]?.Nome_doenca}` },
            { text: dataKit[0].kit[0]?.Parentesco_8 },
            {
              text: dataKit[0].kit[0]?.Outras === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Outras === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],

          [
            {
              text: 'Antecendentes Pessoais',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: `Sofreu ou sofre alguma doença ou situação abaixo? ${sex} ou qualquer um de seus familiares (pai, mãe, irmãos, mesmos que falecidos) tem ou tiveram algumas das doenças abaixo?`,
              colSpan: 8,
              fillColor: '#88CBC5',
              fontSize: 9,
              alignment: 'center'
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Doença ou Situação',
              colSpan: 2,
              fillColor: '#818181',
              color: '#FFF'
            },
            {},
            {
              text: 'Sim',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Não',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Doença ou Situação',
              colSpan: 2,
              fillColor: '#818181',
              color: '#FFF'
            },
            {},
            {
              text: 'Sim',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Não',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            }
          ],
          [
            { text: 'Doenças do coração-Pressão Alta', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Doencas_do_coracao_Pressoao_Alta === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Doencas_do_coracao_Pressoao_Alta === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            { text: 'Varizes', colSpan: 2 },
            {},
            {
              text: dataKit[0].kit[0]?.Varizes === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Varizes === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Bronquite, Asma, Rinite', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Bronquite_Asma_Rinite === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Bronquite_Asma_Rinite === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Hemorroidas', colSpan: 2 },
            {},
            {
              text: dataKit[0].kit[0]?.Hemorroidas === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Hemorroidas === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Doenças renais', colSpan: 2 },
            {},
            {
              text: dataKit[0].kit[0]?.Doencas_renais === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Doencas_renais === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Hérnias', colSpan: 2 },
            {},
            {
              text: dataKit[0].kit[0]?.Hernias === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Hernias === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Doenças do fígado', colSpan: 2 },
            {},
            {
              text: dataKit[0].kit[0]?.Doencas_do_figado === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Doencas_do_figado === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Alguma deficiência física', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Alguma_deficiencia_fisica === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Alguma_deficiencia_fisica === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Diabetes', colSpan: 2 },
            {},
            {
              text: dataKit[0].kit[0]?.Diabetes_2 === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Diabetes_2 === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Já foi submetido a cirurgia?', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Ja_foi_submetido_a_cirurgia === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Ja_foi_submetido_a_cirurgia === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Doenças do estômago', colSpan: 2 },
            {},
            {
              text: dataKit[0].kit[0]?.Doencas_do_estomago === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Doencas_do_estomago === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Já esteve internado?', colSpan: 2 },
            {},
            {
              text: dataKit[0].kit[0]?.Ja_esteve_internado === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Ja_esteve_internado === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Resfriados frequentes, Sinusite', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Resfriados_frequentes_Sinusite === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Resfriados_frequentes_Sinusite === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            { text: 'Dificuldade em tarefa pesada?', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Dificuldade_em_tarefa_pesada === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Dificuldade_em_tarefa_pesada === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Problemas de visão', colSpan: 2 },
            {},
            {
              text: dataKit[0].kit[0]?.Problemas_de_visao === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Problemas_de_visao === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Tabagismo?', colSpan: 2 },
            {},
            {
              text: dataKit[0].kit[0]?.Tabagismo === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Tabagismo === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Problemas de audição', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Problemas_de_audicao === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Problemas_de_audicao === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Etilismo (Bebe)?', colSpan: 2 },
            {},
            {
              text: dataKit[0].kit[0]?.Etilismo_bebe === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Etilismo_bebe === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Otite crônica, Zumbido no ouvido', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Otite_cronica_Zumbido_no_ouvido === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Otite_cronica_Zumbido_no_ouvido === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            { text: 'Se considera acima do peso?', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Se_considera_acima_do_peso === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Se_considera_acima_do_peso === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Dor de cabeça, Tontura, Convulsões', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Dor_de_cabeca_Tontura_Convulsoes === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Dor_de_cabeca_Tontura_Convulsoes === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            { text: 'Sofreu ou sofre de insônia?', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Sofreu_ou_sofre_de_insonia === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Sofreu_ou_sofre_de_insonia === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Doenças de pele', colSpan: 2 },
            {},
            {
              text: dataKit[0].kit[0]?.Doencas_de_pele === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Doencas_de_pele === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Se considera estressado?', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Se_considera_estressado === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Se_considera_estressado === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Dores nas costas', colSpan: 2 },
            {},
            {
              text: dataKit[0].kit[0]?.Dores_nas_costas === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Dores_nas_costas === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Pratica esporte regularmente?', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Pratica_esporte_regularmente === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Pratica_esporte_regularmente === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Reumatismo, Dor nas juntas', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Reumatismo_Dor_nas_juntas === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Reumatismo_Dor_nas_juntas === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            { text: 'Faz uso de medicamento?', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Faz_uso_de_medicamento === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Faz_uso_de_medicamento === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Sofreu alguma fratura óssea', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Sofreu_alguma_fratura_ossea === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Sofreu_alguma_fratura_ossea === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text: `Outra doença? (${dataKit[0].kit[0]?.Nome_doenca_2})`,
              colSpan: 2
            },
            {},
            {
              text: dataKit[0].kit[0]?.Outra_doenca === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text: dataKit[0].kit[0]?.Outra_doenca === 'Não' ? 'X' : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Doença psiquiátrica ', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Doenca_Psiquiatricas === 'Sim' ? 'X' : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Doenca_Psiquiatricas === 'Não' ? 'X' : '',
              alignment: 'center'
            },
            { text: 'Afastado por mais de 15 dias?', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Afastado_por_mais_de_15_dias === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Afastado_por_mais_de_15_dias === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            {
              text: 'DATA DA ÚLTIMA MENSTRUAÇÃO, CASO Sexo Feminino',
              colSpan: 3
            },
            {},
            {},
            {
              text: dataKit[0].kit[0]
                ?.Data_da_ultima_mestruacao_caso_Sexo_Feminino
                ? maskDate(
                    dataKit[0].kit[0]
                      ?.Data_da_ultima_mestruacao_caso_Sexo_Feminino
                  )
                : '',
              colSpan: 5
            },
            {},
            {},
            {},
            {}
          ],

          [
            {
              text: 'Antecedentes Ocupacionais',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: 'Sofreu ou sofre alguma doença ou situação abaixo?',
              colSpan: 8,
              fillColor: '#88CBC5',
              fontSize: 9,
              alignment: 'center'
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Doença ou Situação',
              colSpan: 2,
              fillColor: '#818181',
              color: '#FFF'
            },
            {},
            {
              text: 'Sim',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Não',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Doença ou Situação',
              colSpan: 2,
              fillColor: '#818181',
              color: '#FFF'
            },
            {},
            {
              text: 'Sim',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            },
            {
              text: 'Não',
              alignment: 'center',
              fillColor: '#818181',
              color: '#FFF'
            }
          ],
          [
            { text: 'Já sofreu acidente do trabalho?', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Ja_sofreu_acidente_trabalho === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Ja_sofreu_acidente_trabalho === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text: 'Recebeu indenização por acidente dotrabalho?',
              colSpan: 2
            },
            {},
            {
              text:
                dataKit[0].kit[0]?.Recebeu_indenizacao_acidente_trabalho ===
                'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Recebeu_indenizacao_acidente_trabalho ===
                'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            {
              text: 'Já apresentou alguma doença do trabalho ou profissional?',
              colSpan: 2
            },
            {},
            {
              text:
                dataKit[0].kit[0]
                  ?.Ja_apresentou_alguma_doenca_trabalho_profssional === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]
                  ?.Ja_apresentou_alguma_doenca_trabalho_profssional === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text: 'Já se submeteu a exame complementar anterior',
              colSpan: 2
            },
            {},
            {
              text:
                dataKit[0].kit[0]
                  ?.Ja_se_submeteu_exame_complementar_anterior === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]
                  ?.Ja_se_submeteu_exame_complementar_anterior === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            {
              text: 'Já apresentou afastamento superior a 15 dias por doença dotrabalho?',
              colSpan: 2
            },
            {},
            {
              text:
                dataKit[0].kit[0]
                  ?.Ja_apresentou_afastamento_superior_a_15_dias === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]
                  ?.Ja_apresentou_afastamento_superior_a_15_dias === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text: 'Suas condições de saúde exigem atenção especial?',
              colSpan: 2
            },
            {},
            {
              text:
                dataKit[0].kit[0]?.Suas_condicoes_de_saude_exigem === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Suas_condicoes_de_saude_exigem === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],
          [
            { text: 'Já exerceu atividade com uso de EPI?', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Ja_exerceu_atividade_com_uso_de_EPI === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Ja_exerceu_atividade_com_uso_de_EPI === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            { text: 'Necessita de uso de EPI em suas atividades?', colSpan: 2 },
            {},
            {
              text:
                dataKit[0].kit[0]?.Necessita_de_uso_de_EPI_em_suas === 'Sim'
                  ? 'X'
                  : '',
              alignment: 'center'
            },
            {
              text:
                dataKit[0].kit[0]?.Necessita_de_uso_de_EPI_em_suas === 'Não'
                  ? 'X'
                  : '',
              alignment: 'center'
            }
          ],

          // Colunas de assinaturas
          [
            {
              text: 'Observações',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: `${
                dataKit[0].kit[0]?.observacoes
                  ? dataKit[0].kit[0]?.observacoes
                  : ''
              }\n\n\n\n\n`,
              colSpan: 8
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {}
          ],

          [
            {
              text: 'Assinatura',
              colSpan: 8,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#6D539E',
              color: '#FFF',
              bold: true
            }
          ],

          // medico
          [
            { text: '\n\n\n', colSpan: 3 },
            {},
            {},
            { text: '\n\n\n', colSpan: 3 },
            {},
            {},
            {
              text: '___/___/____',
              colSpan: 2,
              alignment: 'center',
              margin: [0, 15, 0, 0]
            },
            {}
          ],
          // label
          [
            {
              text: 'Médico Examinador',
              colSpan: 3,
              alignment: 'center',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true
            },
            {},
            {},
            {
              text: 'Colaborador',
              colSpan: 3,
              alignment: 'center',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true
            },
            {},
            {},
            {
              text: 'Data',
              colSpan: 2,
              alignment: 'center',
              fillColor: '#88CBC5',
              color: '#000',
              bold: true
            },
            {}
          ]
        ]
      }
    }
  ];

  function Rodape(currentPage: string, pageCounf: string) {
    return [
      {
        text: `${currentPage} / ${pageCounf}`,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 20, 0] // left, top, right, bottom
      }
    ];
  }

  const docDefinitions: any = {
    info: {
      title: dataKit[0].colaborador[0]?.nome_completo,
      subject: dataKit[0].colaborador[0]?.nome_completo,
      creator: dataKit[0].colaborador[0]?.nome_completo,
      producer: dataKit[0].colaborador[0]?.nome_completo,
      keywords: dataKit[0].colaborador[0]?.nome_completo,
      download: dataKit[0].colaborador[0]?.nome_completo,
      filename: dataKit[0].colaborador[0]?.nome_completo,
      downloadName: dataKit[0].colaborador[0]?.nome_completo
    },
    pageSize: 'A4',
    pageMargins: [50, 50, 50, 40], // left, top, right, bottom
    header: [reportTitle],
    content: [fichaClinica],
    fontSize: 5,
    footer: Rodape
  };
  docDefinitions.content.push({
    text: '',
    style: 'header'
    // pageBreak: 'after'
  });

  pdfMake
    .createPdf(docDefinitions)
    .download(
      `FICHA CLÍNICA ARYZ - ${dataKit[0].colaborador[0]?.nome_completo.replace('.',' ')}`
    );
  // pdfMake.createPdf(docDefinitions).open();

  return <div />;
};
